import React from 'react';
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    ButtonGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DatePicker,
    Button,
    Alert
} from 'shards-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { fetchPost, fetchGet } from '../../utils/api';
import { colors } from '../../utils/colors';

class JobDetails extends React.Component {
    state = {
        disabled: false,
        agent: '',
        state: '',
        user: null,
        agents: [],
        noteRequired: false,
        submitting: false,
        rate: '',
        appointmentDate: null,
        notes: [],
        serialNo: '',
        model: '',
        stateNotes: null,
        serialChanged: false,
        customer: null,
        serviceRate: '',
        additionalLine: '',
        description: '',
        history: [],
        options: [],
        no_of_hours: null,
        selectedServiceArea: null,
        dateOfPurchase: null,
        selectedDateOfPurchase: null,
        isAppointmentChange : false,
        messageNote : ''
    }

    async componentDidMount() {
        fetchGet(`/jobs/notes/${this.props.data['job_no']}`,
            (response) => {
                this.setState({ notes: response || [] })
            },
            (response) => {
                console.log('error', response)
            }
        )
        fetchGet(`/jobs/fetch_history/${this.props.data['job_no']}`,
            (response) => {
                this.setState({ history: response || [] })
            },
            (response) => {
                console.log('error', response)
            }
        )
        this.fetchAgentOptions()
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user
        }
    }

    handleStartDateChange(value) {
        this.setState({
            startDate: value,
            isAppointmentChange: true
        });
    }

    handleSelctedDateOfPurchaseChange(value) {
        this.setState({
            selectedDateOfPurchase: moment(value).tz('Africa/Johannesburg').add(value.getHours(), 'hours').add(value.getMinutes(), 'minutes').format()
        });
    }

    handleStartTimeChange(value) {
        this.setState({
            startTime: moment(this.state.startDate).tz('Africa/Johannesburg').add(value.getHours(), 'hours').add(value.getMinutes(), 'minutes').format(),
            isAppointmentChange: true
        });
    }

    fetchAgentOptions() {
        const agent = this.state.user
        if (this.props.data) {
            this.setState({
                options: [
                    {
                        value: agent['uuid'],
                        label: `${agent['full_name']}: ${this.props.data['areas']['code']} ${this.props.data['areas']['description']}`
                    }
                ]
            })
        }
    }

    onAddNote(message = '') {
        if (this.state.note) {
            fetchPost(`/jobs/add_note/${this.props.data.job_no}`,
                {
                    "note": message + this.state.note,
                    "user": this.state.user.uuid
                },
                (response) => {
                    this.setState({
                        messageNote: 'Note has been added',
                        message: null,
                        passed: true,
                        disabled: false,
                        notes: response || [],
                        note: '',
                        isAppointmentChange: false
                    })
                },
                (response) => {
                    console.log('error', response)
                    this.setState({
                        messageNote: response.message
                    })
                }
            )
        } else {
            this.setState({
                messageNote: 'Note has not been added'
            })
        }
    }
 
    fetchUpdateStates() {
        var states = []
        if(this.props.user && this.props.user.sponsor_name === 'ARC'){
            var states = [
                { label: 'In Hand', value: 'in_hand_by_agent' },
                { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                { label: 'Refer to Management', value: 'refer_to_management' },
                { label: 'Completed', value: 'completed' }
            ]
    
            if (this.props.data && this.props.data['state'] === 'cancelled') {
                states = [
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'in_hand_by_agent') {
                states = [
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Completed', value: 'completed' }
                ]
            }


            if (this.props.data && this.props.data['state'] === 'technical_report') {
                states = [
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Completed', value: 'completed' }
                ]
            }


            if (this.props.data && this.props.data['state'] === 'quotation') {
                states = [
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Completed', value: 'completed' }
                ]
            }
    
            if (this.props.data && this.props.data['state'] === 'awaiting_parts_ordered') {
                states = [
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Quotation', value: 'quotation' }
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_not_ordered') {
                states = [
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Quotation', value: 'quotation' }
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_refer_to_management') {
                states = [
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Quotation', value: 'quotation' }
                ]
            }


            if (this.props.data && this.props.data['state'] === 'pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Quotation', value: 'quotation' }
                ]
            }

            if (this.props.data && this.props.data['state'] === 'not_pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Quotation', value: 'quotation' }
                ]
            }
    
            if (this.props.data && this.props.data['state'] === 'closed') {
                states = [
                    { label: 'Closed', value: 'closed' },
                ]
            }
        }else{
          states = [
                { label: 'In Hand', value: 'in_hand_by_agent' },
                { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                { label: 'Refer to Defy', value: 'refer_to_defy' },
                { label: 'Unable to Contact', value: 'unable_to_contact' },
                { label: 'Completed', value: 'completed' }
            ]
    
            if (this.props.data && this.props.data['state'] === 'cancelled') {
                states = [
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }
    
            if(this.props.data && this.props.data['state'] === 'in_hand_by_agent'){
                states = [
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Completed', value: 'completed' }
                ]
            }

            if(this.props.data && this.props.data['state'] === 'quotation'){
                states = [
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Completed', value: 'completed' }
                ]
            }
            if(this.props.data && this.props.data['state'] === 'technical_report'){
                states = [
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Completed', value: 'completed' }
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_ordered') {
                states = [
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' }
                ]
            }
    
            if (this.props.data && this.props.data['state'] === 'awaiting_parts_refer_to_defy') {
                states = [
                    { label: 'Awaiting Parts (Referred)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' }
                ]
            }

                
            if (this.props.data && this.props.data['state'] === 'pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' }
                ]
            }

            if (this.props.data && this.props.data['state'] === 'not_pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' }
                ]
            }
    
            if (this.props.data && this.props.data['state'] === 'closed') {
                states = [
                    { label: 'Closed', value: 'closed' },
                ]
            }
        }


        return states.map(i => {
            return <option value={i.value}>{i.label}</option>
        })
    }

    fetchColour(key) {
        switch (key) {
            case 'completed':
                return { background: '#81A94F' }
            case 'cancelled':
                return { background: '#DD5757' }
            case 'in_hand_by_agent':
                return { background: '#EBB748' }
            case 'awaiting_parts':
            case 'awaiting_parts_not_ordered':
            case 'awaiting_parts_ordered':
            case 'awaiting_parts_refer_to_defy':
            case 'refer_to_defy':
            case 'pickup_for_workshop':
                return { background: '#818EA3' }
            case '':
                return { background: 'rgb(0,123,255)' }
            case 'assigned_to_asc':
                return { background: '#DDDFE1' }
            default:
                return { color: '#495057', background: 'white', borderColor: '#e1e5eb' }
        }
    }

    renderAgentOptions() {
        return this.state.options.map((agent, i) => {
            const value = agent['value']
            const label = agent['label']

            return <option key={i} value={value}>{label}</option>
        })
    }

    renderHistory() {
        return (
            <div className='bg-light p-3 card-small'>
                <div className='px-2 py-1'>
                    Status History
                </div>
                <div className='px-0'>
                    {this.state.history.map((item, i) => {
                        if (item['state_to'].indexOf('invoice') < 0) {
                            const state = item['state_to'] === 'allocated_to_asc' ? 'Allocated to ASC' : item['state_to']
                            return (
                                <div className='py-1 d-flex-to-block' key={i} style={{ fontSize: '0.8rem' }}>
                                    <div className='px-2'>
                                        <span>{moment(item['created_at']).format('YYYY-MM-DD HH:mm')}</span>{'  '}
                                    </div>
                                    <div className='px-2' style={{ minWidth: '140px', fontWeight: 500 }}>
                                        <span className='text-capitalize'>{state.replace(/_/g, ' ')}</span>
                                    </div>
                                    <div className='px-2'>
                                        <span>{item['updated_by_name']}</span>
                                    </div>
                                </div>
                            )
                        }
                        return undefined
                    })}
                </div>
            </div>
        )
    }

    renderNotes() {
        return this.state.notes.map((note, i) => {
            if (note.user) {
                return (
                    <div key={i}>
                        <Alert style={{ borderRadius: '2%', background: colors.LIGHT_YELLOW, color: '#3d5170' }}>
                            {note.note}<br /><b>{note.user['first_name']}{': '}{moment(note['created_at']).format('YYYY-MM-DD HH:mm')}</b>
                        </Alert>
                    </div>
                )
            }
            if (note.agent) {
                return (
                    <div key={i}>
                        <Alert style={{ borderRadius: '2%', background: '#EEF3F6', color: '#3d5170' }}>
                            {note.note}<br /><b>{note.agent['full_name']}{': '}{moment(note['created_at']).format('YYYY-MM-DD HH:mm')}</b>
                        </Alert>
                    </div>
                )
            }
            return undefined
        })
    }

    submit(data) {
        const notes = data['notes'] || this.state.stateNotes || {}
        var oldAppointment = data['appointment_at']
        var appointmentDate = data['appointment_at'] ? moment(data['appointment_at']).format('DD MMM YYYY') : ''
        if (this.state.startDate) {
            appointmentDate = moment(this.state.startDate).format('DD MMM YYYY')
        }
        const appointmentChanged = this.state.startTime && oldAppointment && oldAppointment !== this.state.startTime

        if (this.state.state === 'assigned_to_agent' && !this.state.startTime) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Changing the state to "Assigned" requires an appointment date. Please enter an appointment date and time'
            })
        } else if (this.state.state === 'in_hand_by_agent' && !this.state.startTime) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Changing the state to "In Hand" requires an appointment date. Please enter an appointment date and time'
            })
        } else if (this.state.state === 'completed' && (this.state.additionalLine === 'spaces' || !this.state.additionalLine)) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must select yes or no for Additional Line Items'
            })
        } else if (this.state.state === 'completed' && !notes['complete_note']) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must add a Job Completion Note'
            })
        } else if (this.state.state === 'awaiting_parts_not_ordered' && !notes['awaiting_parts_note']) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must add a Awaiting Parts Note'
            })
        } else if (this.state.state === 'refer_to_defy' && !notes['refer_to_defy_note']) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must add a Refer to Defy Note'
            })
        } else if (this.state.startDate && !this.state.startTime) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Select a date AND time'
            })
        }else if ((this.state.startDate && this.state.selectedDateOfPurchase) || (appointmentDate && this.state.selectedDateOfPurchase)
            && (moment(this.state.selectedDateOfPurchase).format('YYYY-MM-DD') > moment(this.state.startDate).format('YYYY-MM-DD'))) {
                this.setState({
                    warn: true,
                    submitting: false,
                    message: 'Date of purchase can not be greater than the appointment date'
                })
        }else if ((appointmentChanged && !this.state.note && !this.state.noteRequired) || (!this.state.note && this.state.isAppointmentChange)) {
            this.setState({
                warn: true,
                noteRequired: true,
                disabled: true,
                submitting: false,
                isAppointmentChange: false,
                message: "Please indicate why the appointment change in the 'Internal Note' section below"
            })
        } else {
            this.setState({ passed: true, message: 'Updating', disabled: true })
            if (appointmentChanged) {
                const message = `Appointment Changed from ${moment(oldAppointment).format('YYYY-MM-DD HH:mm')}: Reason: `
                this.onAddNote(message)
            }

            var api = `/jobs/update/${data['uuid']}`

            var agent = data['agent']
            if (data['agent'] && typeof data['agent'] === 'object') {
                agent = data['agent']['uuid']
            }

            var rate = data['rate']
            if (data['rate'] && typeof data['rate'] === 'object') {
                rate = data['rate']['uuid']
            }

            const serialNo = this.state.serialNo && this.state.serialNo !== '' ? this.state.serialNo : data['serial_no']
            var hours = this.state.hours !== null ? this.state.hours : 0
            var minutes = this.state.minutes  !== null ? this.state.minutes : 0

            var req = {
                agent: agent,
                "appointment": this.state.startTime,
                "old_appointment": oldAppointment,
                "contact_mobile": data['customer']['contact_mobile'] || data['customer']['contact_home'] || data['customer']['contact_work'],
                "job_no": data['job_no'],
                "job_type": data['job_type'],
                "state_from": data['state'],
                "state": this.state.state,
                "customer": this.state.customer,
                notes: this.state.stateNotes,
                "original_job_no": data['original_job_no'],
                "serial": serialNo,
                "model": this.state.model || data['model'],
                rate: rate,
                "additional_line_item": this.state.additionalLine,
                "completion_description": this.state.description ,
                "no_of_hours": hours + ':' + minutes,
                "date_of_purchase": this.state.selectedDateOfPurchase
            }

            fetchPost(api,
                req,
                (response) => {
                    this.setState({ disabled: false })
                    this.props.onBack()
                },
                (response) => {
                    console.log('error', response)
                    this.setState({ message: response.message, loading: false, disabled: false, isContinue: true })
                }
            )
        }
    }

    render() {
        const data = this.props.data || {};
        var selectedAgent = ''
        if (this.state.options && this.state.options.length > 0 && data['agent']) {
            selectedAgent = this.state.options.find(option => {
                return option['uuid'] === data['agent']['uuid']
            })
            selectedAgent = selectedAgent.value
        }

        var serviceArea = ''
        if (data['areas']) {
            serviceArea = `${data['areas']['code']} - ${data['areas']['description']}`
        }

        
        var appointmentDate = data['appointment_at'] ? moment(data['appointment_at']).format('DD MMM YYYY') : ''
        if (this.state.startDate) {
            appointmentDate = moment(this.state.startDate).format('DD MMM YYYY')
        }

        var dateOfPurchase = data['date_of_purchase'] ? moment(data['date_of_purchase']).format('DD MMM YYYY') : ''
        if (this.state.selectedDateOfPurchase) {
            dateOfPurchase = moment(this.state.selectedDateOfPurchase).format('DD MMM YYYY')
        }

        var appointmentTime = data['appointment_at'] ? moment(data['appointment_at']).format('HH:mm') : ''
        if (this.state.startTime) {
            appointmentTime = moment(this.state.startTime).format('HH:mm')
        }

        const isToday = (someDate) => {
            const today = new Date()
            return someDate.getDate() === today.getDate() &&
                someDate.getMonth() === today.getMonth() &&
                someDate.getFullYear() === today.getFullYear()
        }

        var minStartTime = new Date().setHours(7, 0, 0)
        if (this.state.startDate && isToday(this.state.startDate)) {
            const curHour = new Date().getHours() + 1
            minStartTime = new Date().setHours(curHour, 0, 0)
        }

        var serialRequired = ['awaiting_parts', 'awaiting_parts_not_ordered', 'pickup_for_workshop', 'refer_to_defy', 'completed'].indexOf(this.state.state) > -1
        if (data['job_type'] === 'cash_call') {
            serialRequired = false
        }

        const awaitingStates = ['awaiting_parts', 'awaiting_parts_ordered', 'awaiting_parts_refer_to_defy']
        const isReferToDefy = this.state.state === 'refer_to_defy' || data['state'] === 'refer_to_defy' || false
        const isDisabled = data['state'] === 'completed' || awaitingStates.includes(this.state.state) || awaitingStates.includes(data['state'])
        var disableAwaitingParts = isDisabled
        const disabledNotCompleted = this.state.state !== 'completed'

        if (this.state.state === 'awaiting_parts_not_ordered' || data['state'] === 'awaiting_parts_not_ordered') {
            disableAwaitingParts = false
        } else {
            disableAwaitingParts = true
        }

        var btnSubmitLabel = 'Update Call'

        if (this.state.isContinue) {
            btnSubmitLabel = 'Continue'
        }

        var modelNo = this.state.model || ''
        if (!this.state.model) {
            modelNo = this.state.modelCleared ? '' : data['model']
        }

        var customer = this.state.customer || data['customer'] || {}
        var notes = this.state.stateNotes || data['notes'] || {}

        return (
            <Card small className='mb-4' >
                <CardHeader className='border-bottom'>
                    <h6 className='m-0'>Call Details</h6>
                    <Button theme='secondary' type='button' style={{ float: 'right' }} onClick={() => this.props.onBack()}> Go Back</Button>
                </CardHeader>
                <ListGroup flush>
                    <ListGroupItem className='p-3'>
                        <Row>
                            <Col>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        if (this.state.isContinue) {
                                            this.props.onBack()
                                        } else if (this.state.serialChanged && serialRequired && !this.state.serialNo) {
                                            this.setState({
                                                warn: true,
                                                message: 'Serial number has changed, please ensure you enter it'
                                            })
                                        } else {
                                            this.submit({
                                                submitting: true,
                                                customer: customer,
                                                ...data,
                                                notes: this.state.stateNotes
                                            })
                                        }
                                    }}
                                >
                                    <Row form>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='job_no'>Job No.</label>
                                            <FormInput
                                                id='job_no'
                                                placeholder='Autogenerated'
                                                disabled
                                                value={data['job_no']}
                                            />
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='status'>Status</label>
                                            <FormSelect disabled={isDisabled} style={{ backgroundColor: this.fetchColour(this.state.state || data['state']).background, color: this.fetchColour(this.state.state || data['state']).color || 'white' }} id='state' value={this.state.state || data['state']} onChange={(e) => this.setState({ state: e.target.value })}>
                                                {this.fetchUpdateStates()}
                                            </FormSelect>
                                        </Col>

                                        <Col md='3' className='form-group'>
                                            <label htmlFor='feAddress'>Additional Line Items</label>
                                            <FormSelect disabled={isDisabled || disabledNotCompleted} id='additional_line_item' value={this.state.additionalLine || data['additional_line_item']}
                                                onChange={(e) => this.setState({ additionalLine: e.target.value })}>
                                                <option value='spaces'>Spaces</option>
                                                <option value='no'>No</option>
                                                <option value='yes'>Yes</option>
                                            </FormSelect>
                                        </Col>

                                        {this.props.user && this.props.user.sponsor_name === 'ARC' && (
                                            <Col md='3' className='form-group'>
                                            <label htmlFor='called_at'>Call Logged with Defy</label>
                                            <FormInput
                                                id='called_at'
                                                placeholder='Called Logged with Defy'
                                                value={moment(data['called_at']).format('YYYY-MM-DD')}
                                                disabled
                                            />
                                        </Col>
                                        )}

                                    </Row>

                                    <Row form>
                                        <Col md='6' className='form-group'>
                                            <label htmlFor='called_at'>Agent</label>
                                            <FormSelect
                                                id='agent'
                                                value={this.state.selected || selectedAgent}
                                                required
                                                disabled
                                            >
                                                <option value=''>---</option>
                                                {this.renderAgentOptions()}
                                            </FormSelect>
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='appointment_at'>Appointment Date</label>
                                            <InputGroup className='d-flex my-auto date-range'>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    disabled={isDisabled}
                                                    selected={this.state.appointmentDate}
                                                    minDate={new Date()}
                                                    onChange={(value) => this.handleStartDateChange(value)}
                                                    placeholderText={this.state.startDate || 'Appointment Date'}
                                                    dropdownMode='select'
                                                    value={appointmentDate}
                                                    className='text-center'
                                                />
                                                <InputGroupAddon type='append'>
                                                    <InputGroupText>
                                                        <i className='material-icons'>&#xE916;</i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='appointment_at'>Appointment Time</label>
                                            <InputGroup className='d-flex my-auto date-range'>
                                                <DatePicker
                                                    timeFormat='HH:mm'
                                                    selected={this.state.appointmentDate}
                                                    onChange={(value) => this.handleStartTimeChange(value)}
                                                    placeholderText={this.state.startTime || 'Appointment Time'}
                                                    dropdownMode='select'
                                                    timeIntervals={30}
                                                    minTime={minStartTime}
                                                    maxTime={new Date().setHours(19, 0, 0)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    disabled={!this.state.startDate || isDisabled}
                                                    value={appointmentTime}
                                                    className='text-center'
                                                />
                                                <InputGroupAddon type='append'>
                                                    <InputGroupText>
                                                        <i className='material-icons'>&#xE916;</i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row form>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='customer'>Customer</label>
                                            <FormInput
                                                id='customer'
                                                placeholder='Customer Name'
                                                disabled
                                                value={data['customer'] && data['customer']['full_name']}
                                            />
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='called_at'>Customer Home No.</label>
                                            <FormInput
                                                id='contact_home'
                                                placeholder='Customer Home No.'
                                                value={customer['contact_home']}
                                                onChange={(e) => {
                                                    const update = customer
                                                    update['contact_home'] = e.target.value
                                                    this.setState({
                                                        customer: update
                                                    })
                                                }}
                                            />
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='called_at'>Customer Mobile No.</label>
                                            <FormInput
                                                id='contact_mobile'
                                                placeholder='Customer Mobile No.'
                                                value={customer['contact_mobile']}
                                                onChange={(e) => {
                                                    const update = customer
                                                    update['contact_mobile'] = e.target.value
                                                    this.setState({
                                                        customer: update
                                                    })
                                                }}
                                            />
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='called_at'>Customer Work No.</label>
                                            <FormInput
                                                id='contact_work'
                                                placeholder='Customer Work No.'
                                                value={customer['contact_work']}
                                                onChange={(e) => {
                                                    const update = customer
                                                    update['contact_work'] = e.target.value
                                                    this.setState({
                                                        customer: update
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md='6' className='form-group'>
                                            <label htmlFor='feAddress'>Model No.</label>
                                            <FormInput
                                                id='model'
                                                placeholder={data['model']}
                                                // disabled={true}
                                                value={modelNo}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        this.setState({
                                                            model: e.target.value
                                                        })
                                                    } else {
                                                        this.setState({
                                                            model: e.target.value,
                                                            modelCleared: true
                                                        })
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='feAddress'>Serial No.</label>
                                            <FormInput
                                                id='model'
                                                required={serialRequired}
                                                placeholder='Enter serial no'
                                                maxlength={20}
                                                pattern="^[a-zA-Z0-9]{1,20}$"
                                                title="No more than 20 alphanumeric characters"
                                                disabled={isDisabled}
                                                value={this.state.serialNo || data['serial_no'] || ''}
                                                onChange={(e) => this.setState({
                                                    serialNo: e.target.value
                                                })}
                                            />
                                        </Col>
                                        <Col md='3' className='form-group'>
                                            <label htmlFor='date_of_purchase'>Date Of Purchase</label>
                                            <InputGroup className='d-flex my-auto date-range'>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={this.state.date_of_purchase}
                                                    onChange={(value) => this.handleSelctedDateOfPurchaseChange(value)}
                                                    placeholderText={this.state.selectedDateOfPurchase || 'Date of Purchase'}
                                                    dropdownMode='select'
                                                    value={dateOfPurchase}
                                                    className='text-center'
                                                />
                                                <InputGroupAddon type='append'>
                                                    <InputGroupText>
                                                        <i className='material-icons'>&#xE916;</i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md='12' className='form-group'>
                                            <label htmlFor='issue'>Fault Note</label>
                                            <FormTextarea
                                                disabled
                                                placeholder='Issue'
                                                value={data['issue']}
                                                rows='3'
                                            />
                                        </Col>
                                        <Col md='12' className='form-group'>
                                            <label htmlFor='issue'>Special Instructions</label>
                                            <FormTextarea
                                                disabled
                                                placeholder='Special Instructions'
                                                value={data['special_instructions']}
                                                rows='3'
                                            />
                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md='6' className='form-group'>
                                            <label htmlFor='feAddress'>Address</label>
                                            <FormInput
                                                id='address'
                                                placeholder='Address'
                                                value={data['customer']['address']}
                                                disabled
                                            />
                                        </Col>
                                        <Col md='6' className='form-group'>
                                            <label htmlFor='sponsor_area'>Service Area</label>
                                            <FormInput
                                                id='sponsor_area'
                                                disabled
                                                placeholder='Service Area'
                                                value={this.state.sponsorArea || serviceArea || ''}
                                            />
                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md='4' className='form-group'>
                                            <label htmlFor='retailer_name'>Retailer</label>
                                            <FormInput
                                                id='retailer_name'
                                                placeholder='Retailer'
                                                value={data['retailer_name']}
                                                disabled
                                            />
                                        </Col>
                                        <Col md='4' className='form-group'>
                                            <label htmlFor='retailer_contact'>Retailer Contact</label>
                                            <FormInput
                                                id='retailer_contact'
                                                placeholder='Retailer Contact'
                                                value={data['retailer_contact']}
                                                disabled
                                            />
                                        </Col>
                                        <Col md='4' className='form-group'>
                                            <label htmlFor='job_type'>Call Type</label>
                                            <FormInput
                                                id='job_type'
                                                placeholder='Call Type'
                                                disabled
                                                value={data['job_type'].replace(/_/g, ' ')}
                                            />
                                        </Col>
                                        {this.props.data.insure_type && (<Col md='3' className='form-group'>
                                                <label htmlFor='insure_type'>Insurer Type</label>
                                                {this.props.data.insure_type === 'bosch' && (
                                                    <FormInput
                                                        disabled
                                                        value='Bosch'
                                                    />
                                                )}
                                                {this.props.data.insure_type === 'insurance' && (
                                                    <FormInput
                                                        disabled
                                                        value='Insurance'
                                                    />
                                                )}
                                                {this.props.data.insure_type === 'candy_appliances' && (
                                                    <FormInput
                                                        disabled
                                                        value='Candy Appliances'
                                                    />
                                                )}
                                            </Col>)}
                                    </Row>

                                    <Row>
                                        <Col md='12' className='form-group'>
                                            {this.renderHistory()}
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md='12' className='form-group'>
                                            <label htmlFor='notes'>Awaiting Parts Note</label>
                                            <FormTextarea id='notes'
                                                disabled={disableAwaitingParts}
                                                placeholder='Add Awaiting Parts Note'
                                                value={notes['awaiting_parts_note']}
                                                rows='2'
                                                onChange={(e) => {
                                                    const update = notes
                                                    update['awaiting_parts_note'] = e.target.value
                                                    this.setState({
                                                        stateNotes: update
                                                    })
                                                }} />
                                        </Col>

                                        {this.props.user && this.props.user.sponsor_name !== 'ARC' && (
                                            <Col md='12' className='form-group'>
                                            <label htmlFor='notes'>Refer To Defy Note</label>
                                            <FormTextarea id='notes' disabled={isDisabled || !isReferToDefy} placeholder='Add Refer To Defy Note' value={notes['refer_to_defy_note']} rows='2' onChange={(e) => {
                                                const update = notes
                                                update['refer_to_defy_note'] = e.target.value
                                                this.setState({
                                                    stateNotes: update
                                                })
                                            }} />
                                        </Col>
                                        )}

                                        <Col md='12' className='form-group'>
                                            <label htmlFor='notes'>Job Completion Description</label>
                                            <FormTextarea id='notes' disabled={isDisabled || disabledNotCompleted} placeholder='Add Completion Note' value={notes['complete_note']} rows='2' onChange={(e) => {
                                                const update = notes
                                                update['complete_note'] = e.target.value
                                                this.setState({
                                                    stateNotes: update
                                                })
                                            }} />
                                        </Col>
                                        {(this.state.state === 'completed') && this.props.user && this.props.user.sponsor_name === 'ARC' && (
                                            <Row form>
                                                <Col md='5' className='form-group'>
                                                    <label htmlFor='hours'>Hours</label>
                                                        <FormInput id='notes'  
                                                        type='number'
                                                        min='0'
                                                        max='24'
                                                        required
                                                        placeholder='HH'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                hours: e.target.value
                                                            })
                                                        }} />
                                                    <strong>{data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[0] !== '0' && data['no_of_hours'].split(':')[0] !== 'undefined' ? data['no_of_hours'].split(':')[0] : ''}</strong>
                                                </Col>
                                                :
                                                <Col md='5' className='form-group'>
                                                    <label htmlFor='minutes'>Minutes</label>
                                                        <FormInput id='minutes'
                                                        type='number'
                                                        min='0'
                                                        max='60'
                                                        required
                                                        placeholder='MM'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                minutes: e.target.value
                                                            })
                                                        }} />
                                                    <strong>{data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[1] !== '0' && data['no_of_hours'].split(':')[1] !== 'undefined' ? data['no_of_hours'].split(':')[1] : ''}</strong>
                                                </Col>
                                            </Row>)
                                            }
                                        </Row>
                                            {data['state'] === 'completed' && this.props.user && this.props.user.sponsor_name === 'ARC'&& (
                                            <Row form>
                                                <Col md='5' className='form-group'>
                                                    <label htmlFor='hours'>Hours</label>
                                                        <FormInput id='notes'  
                                                        type='number'
                                                        min='0'
                                                        max='24'
                                                        value={data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[0] !== '0' && data['no_of_hours'].split(':')[0] !== 'undefined' ? data['no_of_hours'].split(':')[0] : ''}
                                                        disabled
                                                        placeholder='HH'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                hours: e.target.value
                                                            })
                                                        }} />
                                                </Col>
                                                :
                                                <Col md='5' className='form-group'>
                                                    <label htmlFor='minutes'>Minutes</label>
                                                        <FormInput id='minutes'
                                                        type='number'
                                                        min='0'
                                                        max='60'
                                                        value={data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[1] !== '0' && data['no_of_hours'].split(':')[1] !== 'undefined' ? data['no_of_hours'].split(':')[1] : ''}
                                                        disabled
                                                        placeholder='MM'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                minutes: e.target.value
                                                            })
                                                        }} />
                                                </Col>
                                            </Row>)
                                            }
                                    {this.state.message && (
                                        <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : this.state.warn ? colors.ORANGE : colors.RED, color: '#ffffff' }}>
                                            {this.state.message}
                                        </Alert>
                                    )}
                                    <ButtonGroup className='mb-4'>
                                        <Button theme='secondary' type='button' onClick={() => this.props.onBack()}> Go Back</Button>
                                        <Button theme='accent' disabled={this.state.disabled || this.state.submitting}>{btnSubmitLabel}</Button>
                                    </ButtonGroup>
                                    <hr />
                                    <Row form className='mt-4'>
                                        <Col md='12' className='form-group'>
                                            <label htmlFor='notes'>(Optional) Internal Notes</label>
                                            {this.renderNotes()}
                                            <FormTextarea id='notes' value={this.state.note || ''} placeholder='Add internal note/comment that can be viewed by admins and agents' rows='5' onChange={(e) => this.setState({ note: e.target.value })} />
                                        </Col>
                                    </Row>
                                    {this.state.messageNote && (
                                        <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                            {this.state.messageNote}
                                        </Alert>
                                    )}
                                    <ButtonGroup>
                                        <Button theme='info' type='button' onClick={() => this.onAddNote()}>Add Note</Button>
                                    </ButtonGroup>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </Card >
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        agents: state.agents
    }
}

export default connect(mapStateToProps)(JobDetails);
